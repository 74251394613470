import get from 'lodash/get';
import assign from 'lodash/assign';
import striptags from 'striptags';

import log from '../../../../services/logger_service';
import identityConfig from '../../../../config/identity';
import mergeContent from '../../../../utils/content_util';
import { isPremium } from '../../advertorial/advertorial';

import {
  buildAssetUrlWithPresets,
  imageSizesLookup,
  changeAssetDomain
} from '../../../../utils/cloudinary_asset_util';

const mapMicYearToDate = year => {
  let finalDate = '2000-01-01T00:00:00Z';

  switch (year) {
    case 'list':
      finalDate = '2024-03-19T11:00:00Z';
      break;

    case '2023':
      finalDate = '2023-03-08T11:00:00Z';
      break;

    case '2022':
      finalDate = '2022-02-20T11:00:00Z';
      break;

    case '2019':
      finalDate = '2019-02-19T11:00:00Z';
      break;

    case '2018':
      finalDate = '2018-02-21T11:00:00Z';
      break;

    case '2017':
      finalDate = '2017-03-17T11:00:00Z';
      break;

    case '2016':
      finalDate = '2016-02-16T11:00:00Z';
      break;

    case '2015':
      finalDate = '2015-02-09T11:00:00Z';
      break;

    case '2014':
      finalDate = '2014-02-10T11:00:00Z';
      break;

    case '2013':
      finalDate = '2013-02-11T11:00:00Z';
      break;

    case '2012':
      finalDate = '2012-02-08T11:00:00Z';
      break;

    case '2011':
      finalDate = '2011-11-30T11:00:00Z';
      break;

    case '2010':
      finalDate = '2010-03-09T11:00:00Z';
      break;

    case '2009':
      finalDate = '2009-02-11T11:00:00Z';
      break;

    case '2008':
      finalDate = '2008-02-15T11:00:00Z';
      break;

    default:
      break;
  }

  return finalDate;
};

/*
Validate your structured data page here (paste in entire view-source: https://search.google.com/structured-data/testing-tool/u/0/
*/
export default function structuredData(
  context,
  location,
  firstLoad,
  unsanitizedURL
) {
  const {
    // authorPageData,
    // categoryPageData,
    // personPageData,
    tagPageData,
    // videoIndexPageData,
    authorAllFeed,
    categoryHomeList,
    companyPageData,
    giftGuideData,
    homeList,
    ibdPageData,
    jwPlaylistIndex,
    micYearPageData,
    micSectorPageData,
    mic2020YearPage,
    mostCreativePeoplePageData,
    playlistAllFeedData,
    postData,
    productPageData,
    searchPageData,
    staticPageData,
    tagHomeList,
    videoPageData,
    worldChangingIdeasPage,
    hubPage,
    queer50Page,
    micHubPage,
    mcpHubPage,
    franchisePage,
    brandsThatMatterPage,
    recognitionHubPage,
    micYearModernPageData
  } = context;
  let { type } = context;

  /**
   * Finds the byline attribution in an array of tags.
   * @param {Array} tags - An array of tag objects.
   * @returns {string|null} - The byline attribution tag, or null if not found.
   */
  const findBylineAttributionTag = tags => {
    let bylineAttribution = null;
    (tags || []).forEach(tagObj => {
      if (
        tagObj.name.indexOf('byline_') !== -1 ||
        tagObj.slug.indexOf('byline-') !== -1
      ) {
        bylineAttribution = tagObj.name.replace('byline_', '');
      }
    });
    return bylineAttribution;
  };

  const buildBrandsThatMatterStructuredData = () => {
    const { wpData, airtableData } = brandsThatMatterPage;
    const defaultTitle = "Fast Company's Brands That Matter";
    const airtableTitle =
      brandsThatMatterPage?.airtableData?.metadata?.PageTitle;
    const wpTitle = brandsThatMatterPage?.wpData?.acf?.title;
    const defaultList = [];
    const airtableList = brandsThatMatterPage?.airtableData?.winners;
    const wpList = brandsThatMatterPage?.wpData?.acf?.brandsThatMatterCompanies;
    const showStructuredData = true;

    // Modern airtable only data item reference
    let nameLocation = 'company';
    const urlLocation = undefined;

    // 2022 airtable and wp combo data item reference
    if (airtableData && wpData) {
      nameLocation = 'title';
    }

    // 2021 initial static data item reference
    if (!airtableData && wpData) {
      nameLocation = 'companyName';
    }

    return {
      '@type': 'Guide',
      name: airtableTitle || wpTitle || defaultTitle,
      dateCreated: '2022-10-25T06:00:41',
      datePublished: '2022-10-25T06:00:41',
      dateModified: '2022-10-25T06:00:41',
      hasPart: showStructuredData
        ? (airtableList || wpList || defaultList).map((item, index) => ({
            '@type': 'Recommendation',
            author: {
              '@type': 'Organization',
              name: 'Fast Company Staff'
            },
            itemReviewed: {
              '@type': 'Organization',
              name: get(item, nameLocation, `Unknown Item ${index}`),
              url: urlLocation
            }
          }))
        : []
    };
  };

  const buildNextBigThingsinTechStructuredData = () => {
    const airtableContent = franchisePage?.data?.externalList;

    const defaultTitle = "Fast Company's Next Big Things In Tech";
    const airtableTitle =
      airtableContent?.metadata?.PageTitle ||
      airtableContent?.data?.Metadata?.[0]?.PageTitle;
    const airtableList =
      airtableContent?.winners || airtableContent?.data?.Winners;

    return {
      '@type': 'Guide',
      name: airtableTitle || defaultTitle,
      dateCreated: '2022-11-20T06:00:41',
      datePublished: '2022-11-20T06:00:41',
      dateModified: '2022-11-20T06:00:41',
      hasPart:
        airtableList?.length > 0
          ? airtableList.map((item, index) => ({
              '@type': 'Recommendation',
              author: {
                '@type': 'Organization',
                name: 'Fast Company Staff'
              },
              itemReviewed: {
                '@type': 'Organization',
                name: get(item, 'company', `Unknown Item ${index}`),
                url: 'https://www.fastcompany.com/next-big-things-in-tech/list'
              }
            }))
          : []
    };
  };

  const buildInnovationByDesignStructuredData = () => {
    // TODO: find and build out data for 2023, 2021 and earlier
    const year = Number(unsanitizedURL.split('/').slice(-1)[0]);
    const date =
      context?.franchisePage?.data?.date || '2024-07-23T07:00:00Z' || false;

    const data = (() => {
      switch (year) {
        case 2024:
          return ibdPageData?.winners || [];

        case 2022:
          return ibdPageData?.winners?.records || [];

        default:
          return [];
      }
    })();

    const buildInnovationByDesignEntry = item => {
      switch (year) {
        case 2024: {
          const { title, url, description, 'Design of the Year': badge } = item;
          return {
            name: title,
            url,
            description,
            ...(badge && { disambiguatingDescription: 'Design of the Year' })
          };
        }

        case 2022: {
          const { title, url, description } = item?.fields;
          return { name: title, url, description };
        }

        default:
          return {};
      }
    };

    return {
      '@type': 'ItemList',
      url: `https://www.fastcompany.com/innovation-by-design/${year}`,
      dateCreated: date,
      datePublished: date,
      dateModified: date,
      itemListElement: data.map(item => ({
        '@type': 'ListItem',
        ...buildInnovationByDesignEntry(item, Number(year))
      }))
    };
  };

  const handleMcpData = url => {
    if (Object.keys(get(mostCreativePeoplePageData, 'data', {})).length > 0) {
      // LEGACY METHOD
      return {
        '@type': 'ItemList',
        url,
        dateCreated: '2000-01-01T00:00:00Z',
        datePublished: '2000-01-01T00:00:00Z',
        dateModified: '2000-01-01T00:00:00Z',
        itemListElement: get(mostCreativePeoplePageData, 'data.rows', []).map(
          (item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            url: `https://www.fastcompany.com/person/${item.url}`
          })
        )
      };
    }

    // MODERN METHOD
    return {
      '@type': 'Guide',
      name: `${get(
        franchisePage,
        'data.acf.franchise_year',
        '2022'
      )} Fast Company's Most Creative People List`,
      dateCreated: '2022-06-08T06:00:41',
      datePublished: '2022-06-08T06:00:41',
      dateModified: '2022-06-08T06:00:41',
      hasPart: get(franchisePage, 'data.externalList.people.records', []).map(
        (item, index) => ({
          '@type': 'Recommendation',
          author: {
            '@type': 'Organization',
            name: 'Fast Company Staff'
          },
          itemReviewed: {
            '@type': 'Person',
            name: get(item, 'fields["Name"]', `Unknown Item ${index}`),
            // position: index + 1,
            url: get(item, 'fields["Article URL"]', '')
          }
        })
      )
    };
  };

  const { config } = context;
  const siteConfig = identityConfig;
  const url = unsanitizedURL.includes('?amp=')
    ? unsanitizedURL.split('?')[0]
    : unsanitizedURL;

  if (siteConfig === undefined) {
    return '';
  }

  const articleType =
    get(postData, 'categories[0].name') === 'News' ? 'NewsArticle' : 'Article';

  let topicList = [];
  let articleTitle = '';
  let articleDescription = '';
  let authorName = '';
  let authorSlug = '';
  let articleSection = '';
  let thumbnail = '';
  let articleDate = '';
  let thumbnailCaption;
  let thumbnailWidth;
  let thumbnailHeight;
  let dateModified;
  const DESKTOP_SIZE = true;

  let livePostStructuredData = {};

  if (postData) {
    topicList = get(postData, 'tags');
    articleTitle = get(postData, 'title');
    articleDescription = get(postData, 'excerpt', '');
    authorName = get(postData, 'author.name');
    authorSlug = get(postData, 'author.slug', '');
    articleSection = get(postData, 'categories[0].slug'); // TODO, needs primary tag if exist
    thumbnail = buildAssetUrlWithPresets(
      changeAssetDomain(get(postData, 'featured_image.source')),
      imageSizesLookup.meta,
      DESKTOP_SIZE
    );

    if (/.gif$/.test(thumbnail)) {
      thumbnail = thumbnail.replace('f_auto', 'f_jpg');
    }

    articleDate = get(postData, 'structuredData.published');
    dateModified = get(postData, 'structuredData.modified');
    thumbnailCaption = striptags(get(postData, 'featured_image.caption') || '');
    thumbnailWidth = get(postData, 'featured_image.width') || 700;
    thumbnailHeight = get(postData, 'featured_image.height') || 700;
  }

  const siteData = {
    humanReadable: 'Fast Company',
    logo: {
      url:
        'https://assets.fastcompany.com/image/upload/v1510092328/fc-logo.webp',
      width: '346',
      height: '60'
    }
  };
  const siteName = get(siteData, 'humanReadable');
  const logo = get(siteData, 'logo.url');
  const logoWidth = get(siteData, 'logo.width');
  const logoHeight = get(siteData, 'logo.height');

  const tags = topicList.map(tag => tag.name);
  const isPremiumArticle = isPremium(topicList);
  // Set specific structured data for live posts
  if (postData) {
    const livePosts = get(postData, 'live.posts', []);
    const livePostsLength = livePosts.length;

    if (livePostsLength) {
      type = 'livePost';
      livePostStructuredData = {
        coverageStartTime: get(livePosts[livePostsLength - 1], 'published'),
        coverageEndTime: get(livePosts, '[0].published'),
        liveBlogUpdate: livePosts.map(post => ({
          '@type': 'BlogPosting',
          headline: articleTitle,
          author: {
            '@type': 'Person',
            name: get(post, 'author.name')
          },
          publisher: {
            '@type': 'Organization',
            name: siteName,
            logo: {
              '@type': 'ImageObject',
              url: logo,
              width: logoWidth,
              height: logoHeight
            }
          },
          url: `${url}#update-${get(post, 'id')}`,
          datePublished: get(post, 'published'),
          articleBody: striptags(mergeContent(get(post, 'content', [])))
        }))
      };
    }
  }

  const baseData = {
    '@context': 'https://schema.org'
  };

  const dupeLookUp = {};
  // We need a default date on landing pages in order for parsely to pick them up correctly.
  const defaultParselyDate = '2000-01-01T00:00:00Z';
  const typeLookup = {
    post: {
      '@type': articleType,
      headline: articleTitle,
      url,
      mainEntityOfPage: {
        '@id': url,
        '@type': 'WebPage'
      },
      description: articleDescription,
      thumbnailUrl: thumbnail,
      dateCreated: articleDate,
      datePublished: articleDate,
      dateModified,
      articleSection,
      creator: authorName,
      author: {
        '@type': 'Person',
        name: authorName,
        url: `https://www.fastcompany.com/user/${authorSlug}`
      },
      keywords: tags,
      image: {
        '@type': 'ImageObject',
        caption: thumbnailCaption,
        url: thumbnail,
        width: thumbnailWidth,
        height: thumbnailHeight
      },
      publisher: {
        '@type': 'Organization',
        name: siteName,
        logo: {
          '@type': 'ImageObject',
          url: logo,
          width: logoWidth,
          height: logoHeight
        }
      },
      // props for paywalled articles
      // https://developers.google.com/search/docs/appearance/structured-data/paywalled-content
      ...(isPremiumArticle && {
        isAccessibleForFree: 'False',
        hasPart: {
          '@type': 'WebPageElement',
          isAccessibleForFree: 'False',
          cssSelector: '#premiumPaywallContainer'
        }
      })
    },
    livePost: {
      '@type': 'LiveBlogPosting',
      url,
      headline: articleTitle,
      datePublished: articleDate,
      coverageStartTime: get(livePostStructuredData, 'coverageStartTime'),
      coverageEndTime: get(livePostStructuredData, 'coverageEndTime'),
      dateModified,
      publisher: {
        '@type': 'Organization',
        name: siteName,
        logo: {
          '@type': 'ImageObject',
          url: logo,
          width: logoWidth,
          height: logoHeight
        }
      },
      liveBlogUpdate: get(livePostStructuredData, 'liveBlogUpdate')
    },
    homepage: {
      '@type': 'CollectionPage',
      name: 'Fast Company',
      url,
      description:
        "Fast Company is the world's leading progressive business media brand, with a unique editorial focus on innovation in technology, leadership, and design.",
      hasPart: {
        '@type': 'ItemList',
        itemListElement: homeList
          // we can only provide same-origin urls for structured data, so we should filter out
          // any urls that don't share the same domain as current homepage
          .filter(item => {
            // list item url is populated in the url field if it's scheduled in manage
            const listItemUrl = item.url || item.link;

            if (listItemUrl) {
              return listItemUrl.indexOf(url) !== -1;
            }

            return false;
          })
          .map((item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            url:
              item.url ||
              `https://www.fastcompany.com/${get(item, 'id')}/${get(
                item,
                'slug'
              )}`
          }))
      }
    },
    category: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: categoryHomeList.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url:
          item.url ||
          `https://www.fastcompany.com/${get(item, 'id')}/${get(item, 'slug')}`
      }))
    },
    tag: () => {
      const tagName = tagPageData?.name || '';
      return {
        '@context': 'https://schema.org',
        '@type': 'CollectionPage',
        name: tagName,
        url,
        description: `Collection of articles tagged with '${tagName}'`,
        hasPart: (tagHomeList || []).map((article, index) => {
          const tagPageBylineAttribution = findBylineAttributionTag(
            article?.tags || []
          );
          const tagPageAuthorName =
            article?.author?.name || `Unknown Author ${index}`;
          const tagPageAuthorSlug = article?.author?.slug;

          return {
            '@type': 'Article',
            headline: article?.title || '',
            url: article?.link || '',
            image: article?.featured_image?.source || '',
            author: {
              '@type': 'Person',
              name: tagPageBylineAttribution || tagPageAuthorName,
              url: `https://www.fastcompany.com/${
                tagPageAuthorSlug ? `/user/${tagPageAuthorSlug}` : ''
              }`
            },
            datePublished: article?.structuredData?.published || '',
            dateModified: article?.structuredData?.modified || ''
          };
        })
      };
    },
    author: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: authorAllFeed.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url:
          item.url ||
          `https://www.fastcompany.com/${get(item, 'id')}/${get(item, 'slug')}`
      }))
    },
    personPage: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: []
    },
    mostCreativePeoplePage: () => {
      const mcpData = handleMcpData(url);
      return mcpData;
    },
    ibdPage: () => {
      const ibdData = buildInnovationByDesignStructuredData();
      return ibdData;
    },
    micYearPage: () => {
      const latestYear = '2024';
      const micYear = get(micYearPageData, 'year', '');
      const isLatestYear = micYear === 'list';
      let itemsArray = get(micYearPageData, 'data.rows', []);
      if (isLatestYear) {
        itemsArray = get(micYearModernPageData, 'data.winners', []);
        itemsArray = itemsArray.map(item => ({
          name: item?.['company name'],
          url: item?.['Article Link']
        }));
      } else if (micYear === '2023' || micYear === '2022') {
        itemsArray = get(micYearModernPageData, 'data.externalList', []);
        itemsArray = itemsArray.map(item => ({
          name: item?.fields?.['Company Name'] || item?.['Company Name'] || '',
          url: item?.fields?.['Article Link'] || item?.URL || ''
        }));
      } else {
        itemsArray = get(micYearPageData, 'data.rows', []);
        itemsArray = itemsArray.map((item, i) => ({
          name: get(item, 'name', `Unknown Item ${i}`),
          url: `https://www.fastcompany.com/company/${item.url}`
        }));
      }

      return {
        '@type': 'Guide',
        name: `Most Innovative Companies ${
          isLatestYear ? latestYear : micYear
        }`,
        dateCreated: mapMicYearToDate(micYear),
        datePublished: mapMicYearToDate(micYear),
        dateModified: mapMicYearToDate(micYear),
        hasPart: itemsArray.map((item, index) => ({
          '@type': 'Recommendation',
          author: {
            '@type': 'Organization',
            name: 'Fast Company Staff'
          },
          itemReviewed: {
            '@type': 'Organization',
            name: get(item, 'name', `Unknown Item ${index}`),
            position: index + 1,
            url: `${item.url}`
          }
        }))
      };
    },
    'wci-list': {
      '@type': 'Guide',
      name: `World Changing Ideas Awards ${get(
        worldChangingIdeasPage,
        'data.acf.franchise_year',
        '2024'
      )}`,
      dateCreated: get(
        worldChangingIdeasPage,
        'data.date',
        '2024-05-03T06:00:41'
      ),
      datePublished: get(
        worldChangingIdeasPage,
        'data.date',
        '2024-05-03T06:00:41'
      ),
      dateModified: get(
        worldChangingIdeasPage,
        'data.date',
        '2024-05-03T06:00:41'
      ),
      hasPart: (
        worldChangingIdeasPage?.data?.listData?.winners?.records ||
        worldChangingIdeasPage?.data?.winners?.records ||
        []
      ).map((item, index) => ({
        '@type': 'Recommendation',
        author: {
          '@type': 'Organization',
          name: 'Fast Company Staff'
        },
        itemReviewed: {
          '@type': 'Organization',
          name:
            item?.fields?.['Company Name'] ||
            item?.fields?.['Project Name'] ||
            `Unknown Item ${index}`,
          // position: index + 1,
          url: get(item, 'fields["Article Link"]', '')
        }
      }))
    },
    btmListPage: () => {
      const btmData = buildBrandsThatMatterStructuredData();
      return btmData;
    },
    'next-big-things-in-tech-list': () => {
      const btmData = buildNextBigThingsinTechStructuredData();
      return btmData;
    },
    queer50Page: () => {
      const q50 = queer50Page?.data;
      const year =
        q50?.acf?.franchise_year ||
        q50?.franchiseCategory?.replace('q50-', '') ||
        '2024';
      const listData =
        q50?.externalList?.list?.records || q50?.externalList?.list || [];

      const publicationDates = {
        '2024': '2024-06-11T07:00:00',
        '2023': '2023-06-13T06:00:00',
        '2022': '2022-06-08T06:00:00',
        '2021': '2021-06-03T06:00:00'
      };

      const queer50StructuredData = {
        '@type': 'Guide',
        name: `${year} Fast Company Queer 50 List`,
        dateCreated: publicationDates[year],
        datePublished: publicationDates[year],
        dateModified: publicationDates[year],
        hasPart: listData.map((item, idx) => {
          const name =
            item?.fields?.Name || item?.Name || `Unknown Item ${idx + 1}`;
          const articleUrl =
            item?.fields?.['Article URL'] || item?.['Article URL'] || null;

          return {
            '@type': 'Recommendation',
            author: {
              '@type': 'Organization',
              name: 'Fast Company Staff'
            },
            itemReviewed: {
              '@type': 'Person',
              name,
              ...(articleUrl && { articleUrl })
            }
          };
        })
      };

      return queer50StructuredData;
    },
    bestWorkplacesPage: {
      '@type': 'Guide',
      name: `Fast Company's Best Workplaces for Innovators List ${get(
        franchisePage,
        'data.acf.franchise_year',
        '2022'
      )}`,
      dateCreated: '2022-08-02T06:00:41',
      datePublished: '2022-08-02T06:00:41',
      dateModified: '2022-08-02T06:00:41',
      hasPart: get(
        franchisePage,
        'data.externalList.workplaces.records',
        []
      ).map((item, index) => ({
        '@type': 'Recommendation',
        position: index + 1,
        author: {
          '@type': 'Organization',
          name: 'Fast Company Staff'
        },
        itemReviewed: {
          '@type': 'Organization',
          name: get(item, 'fields["Company"]', `Unknown Item ${index}`),
          url: `https://www.fastcompany.com/best-workplaces-for-innovators/list/${index +
            1}`
        }
      }))
    },
    hubPage: {
      '@type': 'ItemList',
      url,
      itemListElement: [
        ...get(hubPage, 'data.featured', []),
        ...get(hubPage, 'data.top', [])
      ].map((item, index) => {
        let bylineAttribution = null;

        get(item, 'tags', []).forEach(tagObj => {
          if (
            tagObj.name.indexOf('byline_') !== -1 ||
            tagObj.slug.indexOf('byline-') !== -1
          ) {
            bylineAttribution = tagObj.name.replace('byline_', '');
          }
        });

        const keywords = [
          ...get(item, 'tags', []),
          ...get(item, 'categories', [])
        ]
          // get everything that is not byline
          .filter(obj => !get(obj, 'name', '').includes('byline'))
          .map(obj => obj.name);

        return {
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@type': 'CreativeWork',
            headline: get(item, 'title', `Unknown Headline ${index}`),
            author:
              bylineAttribution ||
              get(item, 'author.name', `Unknown Author ${index}`),
            url: get(item, 'link'),
            datePublished: get(item, 'datePublished'),
            keywords: keywords.join(',')
          }
        };
      })
    },
    micHubPage: {
      '@type': 'CollectionPage',
      name: get(
        micHubPage,
        'data.acf.hub_page.hub_list_headline',
        'Most Innovative Companies Hub'
      ),
      url,
      dateCreated: '2022-04-26T06:00:01',
      datePublished: '2022-04-26T07:00:01',
      dateModified: get(micHubPage, 'data.modified', '2022-05-03T06:00:41'),
      mainEntity: {
        '@type': 'ItemList',
        itemListElement: get(micHubPage, 'data.featuredArticles.posts', []).map(
          (item, index) => ({
            '@type': 'NewsArticle',
            name: get(item, 'title', `Unknown Headline ${index}`),
            image: get(
              item,
              'shareImage',
              'https://images.fastcompany.net/image/upload/v1541101333/fcweb/FC-Twitter-Card_new2_mqyz2q.jpg'
            ),
            url:
              item.link ||
              `https://www.fastcompany.com/most-innovative-companies`
          })
        )
      }
    },
    micIxPage: {
      '@type': 'CollectionPage',
      name: 'Most Innovative Companies Index Page',
      url,
      dateCreated: '2024-01-25T06:00:01',
      datePublished: '2024-01-25T06:00:01',
      dateModified: '2024-01-25T06:00:01',
      mainEntityOfPage: url
    },
    mcpHubPage: {
      '@type': 'CollectionPage',
      name: 'Most Creative People Hub',
      url,
      dateCreated: '2023-07-25T06:00:01',
      datePublished: '2024-07-25T07:00:01',
      dateModified: get(mcpHubPage, 'data.modified', '2022-05-03T06:00:41'),
      mainEntity: {
        '@type': 'ItemList',
        itemListElement: get(mcpHubPage, 'data.featuredArticles.posts', []).map(
          (item, index) => ({
            '@type': 'NewsArticle',
            name: get(item, 'title', `Unknown Headline ${index}`),
            image: get(
              item,
              'shareImage',
              'https://images.fastcompany.net/image/upload/v1541101333/fcweb/FC-Twitter-Card_new2_mqyz2q.jpg'
            ),
            url:
              item.link ||
              `https://www.fastcompany.com/most-innovative-companies`
          })
        )
      }
    },
    mic2020YearPage: {
      '@type': 'ItemList',
      name: 'Most Innovative Companies 2020',
      url,
      dateCreated: '2020-03-10T11:00:00Z',
      datePublished: '2020-03-10T11:00:00Z',
      dateModified: '2020-03-10T11:00:00Z',
      itemListElement: get(mic2020YearPage, 'companies', []).map(
        (item, index) => ({
          '@type': 'ListItem',
          name: get(item, 'title', `Unknown Item ${index}`),
          position: index + 1,
          url: `${item.link}`
        })
      )
    },
    micSectorPage: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: get(micSectorPageData, 'data.rows', []).map(
        (item, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          url: `https://www.fastcompany.com/most-innovative-companies/${get(
            micSectorPageData,
            'data.year'
          )}/sectors/${get(item, 'url')}`
        })
      )
    },
    giftGuidePage: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: get(giftGuideData, 'data.rows', []).map(
        (item, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          url: `https://www.fastcompany.com/gift-guide/holiday-2018/category/${
            item.url
          }`
        })
      )
    },
    productPage: {
      '@type': 'NewsArticle',
      headline: get(productPageData, 'data.rows[0].name'),
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      mainEntityOfPage: url
    },
    companyPage: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: get(companyPageData, 'data.posts', []).map(
        (item, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          url: item.link
        })
      )
    },
    videoIndexPage: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: get(jwPlaylistIndex, 'data.playlists', []).reduce(
        (accum, currVal) => {
          get(currVal, 'playlist', []).forEach(item => {
            const itemUrl = item.link;
            const itemJwId = item.mediaid;

            if (!dupeLookUp.hasOwnProperty(itemJwId)) {
              accum.push({
                '@type': 'ListItem',
                position: accum.length + 1,
                url: itemUrl
              });
              dupeLookUp[itemJwId] = true;
            }
          });
          return accum;
        },
        []
      )
    },
    videoPage: {
      '@type': 'VideoObject',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      name: get(videoPageData, 'data.playlist[0].title', ''),
      description: get(videoPageData, 'data.playlist[0].description', ''),
      thumbnailUrl: buildAssetUrlWithPresets(
        changeAssetDomain(
          get(videoPageData, 'data.playlist[0].featured_image.source', '')
        ),
        imageSizesLookup.meta,
        DESKTOP_SIZE
      ),
      uploadDate: get(
        videoPageData,
        'data.playlist[0].structuredData.published',
        ''
      ),
      transcript: get(videoPageData, 'data.videoSeoText', ''),
      duration: `PT${get(videoPageData, 'data.playlist[0].duration', '')}S`,
      contentUrl: get(videoPageData, 'data.playlist[0].sources[4].file', '')
    },
    videoPlaylistPage: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: get(playlistAllFeedData, 'data.playlist', []).map(
        (video, index) => {
          const itemUrl = video.link;

          return {
            '@type': 'ListItem',
            position: index + 1,
            url: itemUrl
          };
        }
      )
    },
    search: {
      '@type': 'ItemList',
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      itemListElement: get(searchPageData, 'entry', []).map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: item.link
      }))
    },
    page: {
      '@type': 'NewsArticle',
      headline: get(staticPageData, 'title.rendered'),
      url,
      dateCreated: defaultParselyDate,
      datePublished: defaultParselyDate,
      dateModified: defaultParselyDate,
      mainEntityOfPage: url
    },
    applyPage: {},
    recognitionHubPage: {
      '@type': 'CollectionPage',
      name: get(
        recognitionHubPage,
        'acf.franchise_title',
        'Fast Company Lists'
      ),
      url,
      dateCreated: get(recognitionHubPage, 'date', defaultParselyDate),
      datePublished: get(recognitionHubPage, 'date', defaultParselyDate),
      dateModified: get(recognitionHubPage, 'modified', defaultParselyDate),
      mainEntity: {
        '@type': 'ItemList',
        itemListElement: get(
          recognitionHubPage,
          'acf.recognition_hub_list_content.0.franchise_list_entry',
          []
        ).map((item, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          url: item.button_url || item.program_link
        }))
      }
    }
  };

  const JSONMeta =
    typeof typeLookup[type] === 'function'
      ? typeLookup[type]()
      : typeLookup[type];

  const lookUp = typeLookup[type];

  if (!lookUp) {
    log.error(
      `Structured Data Type Lookup: ${type} not found on url: ${get(
        config,
        'navHistory[0]'
      )}`
    );
  }

  return lookUp
    ? `<script type="application/ld+json">${JSON.stringify(
        assign(baseData, JSONMeta)
      )}</script>`
    : '';
}
